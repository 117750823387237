<template>
  <div class="my-nothing">
    <img class="nothing" src="../assets/image/nothing.png" />
    <span>暂无数据</span>
  </div>
</template>

<script>
export default {
  name: "MyNothing",
};
</script>

<style lang="less" scoped>
.my-nothing {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  .nothing {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
  }
  span {
    font-size: 14px;
    color: #f1f1f1;
  }
}
</style>
